.references .ant-input-wrapper.ant-input-group>.ant-input-group-addon,
.references .ant-input-number-wrapper.ant-input-number-group>.ant-input-number-group-addon {
    border: none;
    background: #fff;
    padding: 0 0 0 5px;
}

.references .ant-input-group>.ant-input:first-child,
.references .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
}

.references .ant-input-group>.ant-select {
    width: calc(100% - 37px);
}

.no-references .ant-input-group>.ant-select {
    width: 100%;
}

.references .ant-input-group>.hidden-input-use-add-on {
    width: 37px;
}

.hidden-input-use-add-on>.ant-input-wrapper.ant-input-group>.ant-input {
    display: none;
    padding: 0;
}