@import '~antd/dist/reset.css';

body {
  --layout-body-background: @layout-body-background;
  --primary-color: @primary-color;
  --link-color: @link-color;
  --border-radius-base: 6px;
  --border-color-base: #E0E0E0;
  --border-color-base-inverse: #2d2d2d;
  --invese-color: #112d3d;
  // --background-color-light: @background-color-light;
  // --error-color: @error-color;
  // --success-color: @success-color;
  // --warning-color: @warning-color;

  background: var(--layout-body-background);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #112d3d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.float-right {
  float: right;
}

.display-none {
  display: none;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-bg {
  background: none !important;
}

.no-border {
  border: none !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.image-bordered {
  border-radius: var(--border-radius-base);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
    rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;

}

.ant-card-bordered-extra {
  border-width: 2px !important;
  border-color: var(--primary-color) !important;
}

.ant-card-dashed {
  border-style: dashed !important;
  background-color: #E8EDF0;
  border-color: #C0D3DE !important;
}

.ant-card-cover img {
  max-height: 120px;
  object-fit: cover;
}

.ant-card-disabled {
  pointer-events: none;
}

.ant-card-disabled * {
  color: #8c8c8c !important;
}

.ant-card-focused {
  border-color: transparent !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
    rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
}

.logs-scroll {
  min-height: 350px;
  max-height: 350px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  bottom: 50%;
}

.logs-scroll::-webkit-scrollbar {
  width: 10px;
}

.logs-scroll::-webkit-scrollbar-track {
  border-radius: var(--border-radius-base);
}

.logs-scroll::-webkit-scrollbar-thumb {
  background: #3a3b3d;
  border-radius: var(--border-radius-base);
}

.radio-wrapper {
  background: var(--layout-body-background);
  padding: 20px;
  border-radius: var(--border-radius-base);
}

.ant-layout-sider {
  background: #112D3E !important;
  padding: 0 8px;

  .ant-menu-item {
    padding: 0 12px;
    border-radius: var(--border-radius-base) !important;
  }

  .ant-menu-dark {
    background: #112D3E !important;

    .ant-menu-item-selected {
      background-color: rgba(7, 123, 181, 0.50);
    }
  }

  .projects-button {
    margin: 4px 0;

    button {
      border-color: #077BB5;
      background: none;
      color: white;
      width: 100%;
      height: 40px;
    }
  }
}

.header {
  background-color: white;

  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;

  .ant-btn-ghost-inverse {
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #077BB5;
    padding: 0 5px;
    line-height: 0;

    .button-text {
      color: #077BB5;
      font-size: 10px;
      font-weight: 600;
    }

    .ant-btn-icon {
      margin-inline-end: 0 !important;
      font-size: 18px;
    }
  }
}

.header-hide-mobile {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .header.ant-layout-header {
    padding: 0 20px;
  }
}

.ant-layout-header {
  a:hover {
    background: none;
  }

  .ant-btn-icon {
    color: #077BB5;
  }
}


.ant-btn-github {
  background: black;
  color: white;
  border-color: black;

  &:hover {
    background: black;
    opacity: 80%;
    color: white;
    border-color: black;
  }
}

.ant-btn-google {
  background: #4c8bf5;
  color: white;
  border-color: #4c8bf5;

  &:hover {
    background: #4c8bf5;
    opacity: 80%;
    color: white;
    border-color: #4c8bf5;
  }
}

.ant-btn-gitlab {
  background: #f66d27;
  color: white;
  border-color: #f66d27;

  &:hover {
    background: #f66d27;
    opacity: 70%;
    color: white;
    border-color: #f66d27;
  }
}

.ant-btn-bitbucket {
  background: #0053cc;
  color: white;
  border-color: #0053cc;

  &:hover {
    background: #0053cc;
    opacity: 70%;
    color: white;
    border-color: #0053cc;
  }
}

.ant-btn-codecommit {
  background: #3b48cc;
  color: white;
  border-color: #3b48cc;

  &:hover {
    background: #3b48cc;
    opacity: 70%;
    color: white;
    border-color: #3b48cc;
  }
}

.footer {
  background: var(--invese-color) !important;
  padding: 30px 0;

  a,
  span {
    color: white;
  }

  .ant-layout-footer {
    @media screen and (max-width: 800px) {
      width: 100%;

      div {
        padding-bottom: 5px;
      }
    }

    @media screen and (min-width: 800px) {
      width: 70rem;
    }

    text-align: center;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    background: var(--invese-color) !important;
    color: var(--primary-color);
  }
}


.ant-timeline-item-tail {
  border-color: #e2e4e7 !important;
}

.ant-timeline-item-last {
  padding-bottom: 0px !important;
}

.ant-timeline-item-last>.ant-timeline-item-content {
  min-height: 0 !important;
}

.ant-card-meta-title {
  margin: 0 !important;
}

.ant-radio-button-wrapper {
  height: auto !important;
}

.site-page-header {
  border-radius: var(--border-radius-base);
}

.ant-layout-content {
  border-radius: var(--border-radius-base);
}

.bordered {
  border: 1px solid rgb(235, 237, 240);
}

// .ant-input-affix-wrapper {
//   padding-top: 4px;
//   padding-bottom: 4px;
// }

.auth-form {
  .ant-form-item-label {
    label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .providers-section {
    margin: 25px 0;

    .provider-btn {
      width: 48%;
      height: 40px;
    }
  }

  .input-field-section {
    margin-bottom: 15px;
    display: flex;

    .input-icon {
      width: 34px;
      padding-top: 10px;
    }

    .input-form-item {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .log-in-btn {
    color: #2994c2;
    border-color: #2994c2;
  }
}

.forgot-password-btn {
  float: right;
  margin-top: -10px;
  margin-bottom: 10px;
}

.grid-list {
  .ant-card-meta-detail {
    overflow: visible;

    .ant-card-meta-title {
      padding-top: 3px;
    }

    .ant-card-meta-description {
      margin-top: 20px;
      min-height: 65px;
    }
  }

  .ant-card-footer {
    display: flex;
    justify-content: space-between;
    color: GrayText !important;

    :last-child {
      margin-right: 0;
    }
  }
}

.alert-badge {
  // padding: 2px 10px;
  // border: solid 1px;
  // border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #E1265E;
  // border-color: #E1265E;
  // background-color: #e1264e24;
}

.alert-badge-sm {
  // padding: 0 6px;
  font-size: 14px;
  font-weight: bold;
}

.ant-card-actions {
  border-radius: 0 0 6px 6px;
}

.ant-menu {
  border-radius: var(--border-radius-base);
  font-size: 14px;
  font-weight: 500;
}

.ant-table {
  border: solid 1px #e0e0e0;
  border-radius: var(--border-radius-base) !important;

  .ant-table-header {
    border-radius: 6px 6px 0 0 !important;
  }
}

.ant-table-wrapper-bordered {
  border: 1px solid #f0f0f0;
  border-radius: var(--border-radius-base);
}

.ant-table-wrapper-bordered,
.ant-card-bordered {
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.ant-table-expanded-row {
  .ant-table-summary {
    // background: @table-body-sort-bg;
  }

  .ant-table-row {
    // background: @table-body-sort-bg;
  }
}

.ant-scollable-modal {
  .ant-list {
    .ant-list-items {
      max-height: 500px;
      overflow-y: scroll;
      scroll-margin: 50px;
      margin: 0 -15px;
      padding: 0 15px;
    }
  }
}

.ant-list-wrapper-bordered {
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);

  .ant-list-item:last-child {
    border-top-left-radius: var(--border-radius-base);
    border-top-right-radius: var(--border-radius-base);
  }

  .ant-list-item:last-child {
    border-bottom-left-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
  }
}

html,
body {
  overflow-x: hidden; // dont even think about touching this, crucial for mobile responsiveness
}

.page-content-wrapper-div {
  @media screen and (max-width: 1000px) {
    padding: 0 20px 0 20px;
  }
}

#auth-root {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--layout-body-background);
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    display: inherit;
    padding: 20px;
  }
}

.login-card {
  margin: auto;

  @media screen and (min-width: 600px) {
    min-width: 400px;
  }
}

.ant-menu {
  border: 0 0 5px 5px;
}

.ant-page-header-wrapper {
  &-header {
    background-color: white;

    .ant-page-header {
      margin-left: 50px;
      margin-right: 50px;
      padding: 25px 0;

      .ant-page-header-content {
        padding-top: 0;
      }
    }
  }

  &-content {
    padding-left: 0;
    padding-right: 0;
    // width: 70rem;
    margin-left: 50px;
    margin-right: 50px;
  }

  @media screen and (max-width: 1000px) {
    &-content {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

.flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-column-justify-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-justify-space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.flex-justify-center {
  display: flex !important;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-align-start {
  display: flex;
  align-items: start;
}

.widget-skeleton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
}

.ant-list,
.ant-table {
  z-index: 0;

}

.ant-table-selectable {
  .ant-table-row {
    cursor: pointer !important;
  }
}

.ant-typography.link {
  color: var(--link-color)
}

.ant-table-title {
  border-bottom: 1px solid var(--border-color-base);
  background-color: #fafafa;
}

.ant-table-thead>tr>th.ant-table-cell::before {
  display: none;
}

// break words in each table cell except the first one (applicable to every table)
.ant-table-tbody>tr>td:nth-child(1n+2) {
  word-break: break-all;
}

.ant-table-wrapper-bordered {
  .ant-table-tbody>tr>td {
    background: #fff;
  }

  .ant-table-tbody>tr:last-child>td {
    border-bottom: none;
  }
}

.ant-table-footer {
  border-top: 1px solid var(--border-color-base);
  border-bottom-left-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.content-logs {
  background: #121212;
  color: #fff;
  padding: 24px;
  border: 1px solid #121212;
  border-radius: var(--border-radius-base);
  overflow-x: scroll;
  height: 100%;

  code {
    font-size: 12px;
  }

  .log-line {
    display: flex;
    cursor: pointer;
    border-radius: var(--border-radius-base);
    padding: 3px;

    &:hover {
      background-color: #262626;

      .log-time {
        background-color: #262626;
      }

      .log-text {
        background-color: #262626;
        border-radius: var(--border-radius-base);
      }
    }

    .log-time {
      color: #097BB5;
      white-space: nowrap;
    }

    .log-text {
      white-space: pre-wrap;
      width: 100%;
      word-break: break-all;
    }
  }

  .ant-table {
    background: none !important;

    // .ant-table-title {
    //   color: white;
    //   background: none !important;
    // }

    .ant-table-row {
      background: none !important;

      .ant-table-cell {
        color: white;
        border-color: var(--border-color-base-inverse);
        background: none !important;
      }
    }
  }
}

.position-fixed {
  position: fixed;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #2e9a00;
}

.ant-steps-item-active {
  &.ant-steps-item-error {
    .ant-steps-item-icon {
      background: red;
    }
  }

  .ant-steps-item-icon {
    background: var(--primary-color);

    .ant-steps-icon {
      color: white;
    }
  }
}

.ant-table-pagination.ant-pagination {
  margin: 16px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.ant-card-small {
  font-size: 12px;

  .ant-card-meta-title {
    font-size: 14px;
  }

  .ant-card-meta-description {
    font-size: 12px;
  }
}

// Ace Editor

.ace_gutter {
  z-index: 0;
}

.ant-tabs-tab .anticon {
  margin-right: 0;
}

.ant-tabs-nav {
  // display: flex;
  // width: 100%;

  // .ant-tabs-nav-list {
  //   width: 100%;
  //   justify-content: space-around;

  //   .ant-tabs-tab {
  //     flex: 1;
  //     justify-content: center;
  //   }
  // }

  .ant-tabs-tab {
    padding: 12px 30px;
  }
}

.login-logo {
  margin-right: auto;
  height: 40px;
  margin-left: auto;
  width: 70%;
  display: block;
}

#deployment-cards {
  width: 100%;
}

#deployment-cards-git-buttons {
  justify-content: center;

  @media screen and (max-width: 600px) {
    button {
      margin: 0 5px;
    }
  }
}

.ant-tag-lg {
  font-size: 15px;
  padding: 5px 10px;
  margin: 0;
}

.ant-tag-success,
.ant-tag-green {
  color: #2E9A00 !important;
  background: #F6FFED !important;
  border-color: #2E9A00 !important;
}

.ant-tag-processing {
  color: #077BB5 !important;
  background: #e6f4ff !important;
  border-color: #077BB5 !important;
}

.ant-tag-warning {
  color: #faad14 !important;
  background: #fffbe6 !important;
  border-color: #ffe58f !important;
}

.deploy-template-summary-step-title {
  font-weight: 500;
  font-size: 16px;
}

.deploy-template-details-title {
  font-weight: 700;
  font-size: 14px;
}

.deploy-template-details {
  margin-bottom: 25px;
}

.grid-list-card .ant-card-body {
  padding: 20px;


  .ant-statistic {
    .ant-statistic-skeleton {
      padding-top: 0;
    }

    .ant-statistic-content {
      font-size: 15px;
      font-weight: 600;
    }
  }

}

// align the title with the avatar in the card
.grid-list-card .ant-card-meta-title {
  padding-top: 0 !important;
  font-size: 18px;
}

.grid-list-card .ant-card-meta-avatar {
  padding-right: 8px;
}

.import-app-grid-item {
  border: 2px dashed #999999;
}

.apps-items>div {
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.summary-badge {
  margin: auto 8px;
}

.summary-badge>.ant-badge-status-text {
  margin-left: 0;
}

.custom-breadcrumb {
  font-size: 12px;

  & li a:hover {
    color: var(--primary-color);
  }
}

.flex-justify-end {
  display: flex !important;
  justify-content: flex-end;

  @media screen and (max-width: 991px) {
    justify-content: flex-start;
  }
}

.gray-text {
  color: gray;
}

.single-description-item {
  padding-bottom: 0px !important;
}

.cluster-item-title {
  padding: 6px 0;

  & div:last-child {
    text-align: right;

    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }
}

.cluster-item {
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;

  >div {
    @media screen and (max-width: 768px) {
      padding: 7px 0;
    }
  }

  >div:last-child {
    text-align: right;

    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}


.pipelines-item {
  padding: 10px 0;
  border-bottom: 1px solid lightgray;
  font-size: 12px;
}

.pipelines-item:nth-child(even) {
  background-color: whitesmoke;
}

.infinite-scroll-loading {
  margin-top: 15px;
  text-align: center;
  color: lightgray;
  font-size: 30px;
}

.primary-color {
  color: var(--primary-color)
}

.tooltip-copyable {
  color: #fff;
  font-size: 80%
}

.explanation-title {
  font-weight: 600;
  font-size: 16px;
}

.explanation-subtitle {
  color: gray;
}

.build-stages-wrapper {
  display: flex;
  justify-content: space-around;
  flex-flow: row;
}

.build-stages-wrapper>div {
  display: inline-block;
  flex: 1 100%;
  margin: 5px;
}

.build-stages-title {
  background-color: var(--invese-color);
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px;
  border-radius: var(--border-radius-base);
  margin-bottom: 10px;
}

.deploy-template-steps {
  cursor: not-allowed; // needed for the disabled steps (wait status)

  & :is(.ant-steps-item-wait, .ant-steps-item-active) .ant-steps-item-tail::after {
    background-color: #b0b0b0;
  }
}

.combined-input-and-select {
  & .ant-input-group-addon {
    width: auto;
    padding: 0;
  }

  & .ant-select-selector {
    padding: 0 !important;
  }
}

.ant-select-deploy-versions {
  height: 70px;

  .ant-select-selection-item {
    line-height: 18px !important;
  }
}

.error-field-wrapper {
  background: #fff;
  border-color: #ff4d4f;
}

.edit-circle-icon {
  border: 1px solid #097BB5;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-not-found {
  #header {
    color: var(--primary-color);
    font-size: 40px;
  }

  #body {
    font-size: 18px;
  }
}

// .ant-steps {
//   .ant-steps-item-content {
//     width: 100%;

//     .ant-steps-item-description {
//       max-width: 100% !important;
//     }
//   }
// }

// .pipeline-build-steps {
//   .ant-steps {
//     .ant-steps-item-container {
//       margin: 10px 0 0 -32px;
//       .ant-steps-item-content {
//         width: 50% !important;

//         .ant-steps-item-description {
//           max-width: none !important;
//         }

//       }
//     }
//   }
// }

// .pipeline-build-steps .ant-steps-item-active {
//   font-weight: 600;
// }

.template-pipeline-steps .ant-steps-item-active {
  font-weight: 600;
}

.span-link {
  color: var(--link-color);
  cursor: pointer;
}

.pulsating-circle {
  position: absolute;
  left: 15px;
  top: 12px;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: 15px;
}

.pulsating-circle-green {
  position: absolute;
  left: 10px;
  top: 12px;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
}

.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 200%;
  height: 200%;
  box-sizing: border-box;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 45px;
  background-color: var(--primary-color);
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.pulsating-circle-green:before {
  content: "";
  position: relative;
  display: block;
  width: 200%;
  height: 200%;
  box-sizing: border-box;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 45px;
  background-color: green;
  -webkit-animation: pulse-ring 1.5s cubic-bezier(0.45, 0.84, 0.37, 0.88) infinite;
  animation: pulse-ring 1.5s cubic-bezier(0.45, 0.84, 0.37, 0.88) infinite;
}

.pulsating-circle-green:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: green;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}


.gray-circle {
  position: absolute;
  left: 10px;
  top: 12px;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: grey;
  border-radius: 15px;
}

@-webkit-keyframes pulse-ring {
  0% {
    transform: scale(0.2);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.2);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

.message-card .ant-card-body {
  padding: 15px;
}

.weekdays-checkbox .ant-checkbox-inner {
  border: 1px solid var(--primary-color);
}

.cta-box {
  background: #f1f7f1;
  border-radius: var(--border-radius-base);
  padding: 5px 15px;
  border: 1px solid #c5d3c6
}

.pt-5 {
  padding-top: 5px;
}

.cost-dashboard-screenshot {
  width: 100%;
  border-top: 1px solid var(--border-color-base);
  border-left: 1px solid var(--border-color-base);
  border-top-left-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);
  margin-top: 44px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px
}

.ant-breadcrumb {
  ol {
    display: flex;
    align-items: center;
  }

  .ant-breadcrumb-link {
    cursor: pointer;
  }
}

.ant-collapse {
  background-color: #fafafa;
}

.environment-overview {
  .ant-collapse {
    .ant-collapse-item {
      border: solid 1px !important;
      border-color: #E0E0E0 !important;
      border-radius: var(--border-radius-base) !important;

      .ant-collapse-header {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0px;
        }
      }
    }

    .ant-collapse-item-active {
      border: solid 1px;
      border-radius: var(--border-radius-base);
      border-color: #808080 !important;

      &.ant-collapse-item-with-content {
        .ant-collapse-header {
          border-radius: 6px 6px 0 0 !important;
        }
      }

      .ant-collapse-header {
        background-color: #e9eff2;
        border-radius: 6px 6px !important;

        &.ant-collapse-header-selected-children {
          background: none;
        }
      }
    }
  }
}

.environment-overview {
  .ant-tree-list {
    margin-top: 0 !important;
    // margin-left: 15px;

    .ant-tree-treenode {
      width: 100%;
      cursor: pointer;
      padding: 2px 4px;

      &.ant-tree-treenode-selected {
        .ant-tree-node-content-wrapper {
          background: #e9eff2;
        }
      }

      .ant-tree-switcher {
        margin-left: 15px;
      }

      .ant-tree-node-content-wrapper {
        width: 100%;
        padding: 14px;
        margin-left: -39px;
        padding-left: 50px;
      }

      .ant-tree-switcher-leaf-line {
        margin-top: 21px;
      }
    }

    .ant-tree-treenode:first-child {
      padding: 4px 4px 2px 4px;
    }

    .ant-tree-treenode:last-child {
      padding: 2px 4px 4px 4px;
    }

    .ant-tree-treenode:first-child {
      padding: 4px;
    }
  }
}

.ant-progress-line {
  margin-bottom: 4px;
}

.ant-divider {
  border-color: var(--border-color-base);
}

// iframe#webpack-dev-server-client-overlay {
//   display: none !important
// }

.build-pipeline {
  .ant-steps-item-title {
    width: 100%;
  }

  .build-step {
    color: #000000e0;
    padding: 8px;
    margin-bottom: 2px;
    border-radius: var(--border-radius-base);
    cursor: pointer;

    &:hover {
      background-color: #e9eff2;
    }

    &.build-step-selected {
      background-color: #e9eff2;
      font-weight: 700;
    }
  }
}

.build-stage {
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: var(--border-radius-base);

  &-selectable {
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none;
}

.ant-alert {
  border-radius: var(--border-radius-base);
}

.main-menu {
  .ant-divider {
    border-color: #051B29;
    margin: 16px 0;
  }

  .info-box {
    background-color: #051B28;
    padding: 16px 8px;
    font-size: 12;
    border-radius: var(--border-radius-base);
  }
}

.ant-select-tree {
  .ant-select-tree-list-holder {
    .ant-select-tree-switcher {
      padding: 4px;
    }
  }
}

.hide-time-label {
  .time-label {
    display: none;
  }
}

.rd3t-link {
  // stroke: rgba(0, 0, 0, 0.88) !important;
}

.rd3t-node {
  // stroke: rgba(0, 0, 0, 0.88) !important;
}

iframe {
  display: none;
}

.monitoring-chart {
  .ant-card-head {
    border-bottom: none;
  }
}

.monitoring-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  width: 30%;
}

.monitoring-menu-icon-down::after {
  transform: rotate(45deg) translateX(-2.5px);
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 6px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
  color: currentcolor;
  line-height: 40px;
}
.monitoring-menu-icon-down::before {
  transform: rotate(-45deg) translateX(2.5px);
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 6px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
  color: currentcolor;
  line-height: 40px;
}
.monitoring-menu-icon-up {
  padding-bottom: 8px;
}
.monitoring-menu-icon-up::before {
  transform: rotate(-45deg) translateX(-2.5px);
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 6px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
  color: currentcolor;
  line-height: 40px;
}
.monitoring-menu-icon-up::after {
  transform: rotate(45deg) translate(2.5px);
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 6px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
  color: currentcolor;
  line-height: 40px;
}

.nested-menu-item {
  padding-left: 36px !important;
}

.chat {
  height: calc(100vh - 64px - 90px - 32px - 32px);

 .chat-primary-color-bg {
   border-radius: 8px;
 
   .ant-pro-chat-list-item-message-content {
     background: var(--primary-color);
 
     .ant-typography p {
       color: #fff;
     }
   }
 }
 
 .chat-form-item {
   .ant-form-item-label {
     margin-right: 60px;
   }
 }
}
 
@primary-color: #097BB5;@link-color: #097BB5;@height-base: 34px;@border-radius-base: 5px;@border-color-base: #e0e0e0;@btn-font-weight: 500;@form-item-margin-bottom: 15px;@layout-body-background: whitesmoke;